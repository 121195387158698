import logo from './logo.png';
import background from './background.jpg';
import UnderConstruction from './components';

function App() {
  return <UnderConstruction
    background={{
      image: background,
      textColor: '#fff',
      overlay: {
        color: '#000',
        opacity: '.5'
      }
    }}
    logo={{
      src: logo,
      alt: 'logo',
      style: {
        width: '250px'
      }
    }}
    description={{
      text: 'Nuestro sitio web está en construcción. contactanos por Correo Electrónico para saber más de nosotros',
      style: {
        maxWidth: '440px',
      }
    }}
    // subscribe={{
    //   placeholder: 'Enter your email',
    //   buttonText: 'Subscribe',
    //   onSubmit: (value) => {
    //     console.log('user typed email :', value);
    //   }
    // }}
    links={[
      // {
      //   url: 'https://www.instagram.com/servicios_audiovisuales/',
      //   image: 'https://cdn.cdnlogo.com/logos/i/32/instagram-icon.svg',
      // },
      // {
      //   url: 'https://www.facebook.com/servicios_audiovisuales',
      //   image: 'https://cdn.cdnlogo.com/logos/f/91/facebook-icon.svg',
      // },
      {
        url: 'mailto:soporte@serviciosaudiovisuales.cl',
        image: 'https://cdn.cdnlogo.com/logos/m/46/mail-ios.svg',
      },
    ]}
  />
}

export default App;
